import toMilliseconds from "@sindresorhus/to-milliseconds";
import { bindURLS } from "../../constants";
import {
  environment,
  getEnvKey,
  getEnvValue as _getEnvValue,
  getNumber,
  isTrue,
} from "../../utils/env";
import { getQueryParams } from "../../utils/queryParams";

/** @type {string | undefined} */
export const BIND_URL = getEnvValue("BIND_URL", false) || bindURLS[environment];

export const RESUBMIT_RESPONSE_FEATURE_ENABLED = (() => {
  const defaultValue = false;
  const fromUrl = getQueryParams()?.["resubmitSingle"];

  if (fromUrl != null) {
    return isTrue(fromUrl, defaultValue);
  }

  return isTrue(getEnvValue("FEATURE_ENABLED"), defaultValue);
})();

export const RESUBMIT_RESPONSE_FEATURE_FORCE = isTrue(
  getQueryParams()?.["resubmitSingleForce"],
  false
);

export const SKIP_DELETE = isTrue(getEnvValue("SKIP_DELETE"), null) ?? false;

export const RESUBMIT_RESPONSE_POLL_TIMEOUT = getNumber(
  getEnvValue("POLL_TIMEOUT"),
  toMilliseconds({
    minutes: 2,
  })
);

export const RESUBMIT_RESPONSE_POLL_RETRY_COUNT = getNumber(
  getEnvValue("POLL_RETRY_COUNT"),
  10
);

export const RESUBMIT_RESPONSE_POLL_INTERVAL = getNumber(
  getEnvValue("POLL_INTERVAL"),
  RESUBMIT_RESPONSE_POLL_TIMEOUT / RESUBMIT_RESPONSE_POLL_RETRY_COUNT
);

// helpers:

function getFeatureKey(key) {
  return getEnvKey(`RESUBMIT_RESPONSE_${key}`);
}

function getEnvValue(key, namespaceToFeature = true) {
  return _getEnvValue(namespaceToFeature ? getFeatureKey(key) : key);
}
