import { isEmpty, omitBy, isNil } from "lodash";
import {
  POLICY_MAP,
  Tenant,
  policyFormTypes,
  propertyDefaults,
  stateSpecificAopOptions,
  stateSpecificBodilyInjuryOptions,
  stateSpecificWindHailOptions,
} from "../../constants";
import { useState } from "react";
import {
  calculateEffectiveDate,
  propertyValueExistsInObject,
} from "../../utils/misc";
import {
  filterDuplicatedPolicies,
  filterPoliciesWithAddressStateCity,
  orderPoliciesByStatus,
  sanitizeCoverage,
  getRoofReplacedYear,
  getHeatingPlumbingElectricalDefaults,
} from "../../utils/reshop";
import {
  getMonthsFromCurrentDate,
  getYearsFromCurrentDate,
} from "../../utils/dates";

import CustomButton from "../styled/buttons/CustomButton";
import { CustomText } from "../styled/typography";
import Policy from "./Policy";
import ReshopModal from "../modals/ReshopModal";
import { addContact } from "../../redux/actions/contact";
import { addCoverages } from "../../redux/actions/coverages";
import { addProperty } from "../../redux/actions/property";
import { addPropertyData } from "../../services/property";
import {
  getDownloadsByPolicyId,
  getMortgageeDetailsByPolicyId,
} from "../../services/account";
import { getPagenumber } from "../../utils/strings";
import { handleUpdateSession } from "../../services/utils";
import { mapCoveragesFromDownload } from "../../utils/quotes";
import {
  processImprovements,
  mapFoundationFromPolicy,
} from "../../utils/propertyProcess";
import { reshopModalText } from "../../utils/constants";
import store from "../../redux/store";
import styled from "styled-components";
import { updateIsFetching } from "../../redux/actions/fetching";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PolicyTable = styled.table`
  width: 90%;
  color: ${(props) => props.theme.primary};
  font-size: ${(props) => props.theme.fontSize["medium"]};
  th {
    padding: 1rem;
    padding-bottom: 0.1rem;
  }
  td {
    padding: 1rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
`;

const ReShop = ({ policies }) => {
  const session = useSelector((store) => store.session);
  let { homePolicies, autoPolicies } = policies;

  homePolicies = filterPoliciesWithAddressStateCity(homePolicies);
  autoPolicies = filterPoliciesWithAddressStateCity(autoPolicies);

  homePolicies = orderPoliciesByStatus(homePolicies);
  autoPolicies = orderPoliciesByStatus(autoPolicies);

  homePolicies = filterDuplicatedPolicies(homePolicies);
  autoPolicies = filterDuplicatedPolicies(autoPolicies);

  const [selectedHomePolicyIndex, setSelectedHomePolicyIndex] = useState(null);
  const [selectedAutoPolicyIndex, setSelectedAutoPolicyIndex] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let selectedHomePolicy = null;
  let selectedAutoPolicy = null;

  if (homePolicies.length) {
    selectedHomePolicy = homePolicies[selectedHomePolicyIndex];
  }
  if (autoPolicies.length) {
    selectedAutoPolicy = autoPolicies[selectedAutoPolicyIndex];
  }

  const mapPropertyDataFromPolicy = async (policy) => {
    let propertyDataFromPolicy;
    // moving this logic outside so that we are still able to send data from SF if the propertyData call errors out
    let improvementsData = {
      squareFoot: policy.property?.Square_Footage__c,
      yearBuilt: policy.property?.Year_Built__c,
      roofReplaced: getRoofReplacedYear(policy.property ?? {}),
      foundationType: mapFoundationFromPolicy(policy.property?.Foundation__c),
      plumbing: getHeatingPlumbingElectricalDefaults(
        policy.property?.Year_Plumbing_Update_2__c ?? ""
      ),
      heating: getHeatingPlumbingElectricalDefaults(
        policy.property?.Year_Heating_Update_2__c ?? ""
      ),
      electrical: getHeatingPlumbingElectricalDefaults(
        policy.property?.Year_Electrical_Update_2__c ?? ""
      ),
    };
    //remove nullish, read this in context with line 139-140
    improvementsData = omitBy(improvementsData, isNil);

    try {
      const {
        Property_Address__c,
        Property_City__c,
        Property_State__c,
        Property_Zip__c,
        County__c,
      } = policy;

      propertyDataFromPolicy = {
        street_number: Property_Address__c,
        locality: Property_City__c,
        administrative_area_level_2: County__c,
        administrative_area_level_1: Property_State__c,
        country: "US",
        postal_code: Property_Zip__c,
      };
      const response = await addPropertyData(propertyDataFromPolicy);
      const propertyData = response.data.corePropertyData;
      const improvementsDataFromProperty = propertyData
        ? processImprovements(propertyData)
        : {};
      if (!isEmpty(improvementsDataFromProperty)) {
        improvementsData = {
          // data from SF takes priority
          // improvementsData - from SF and initialized at the start of function
          // original comments:
          // read foundationType from property in SF if exists
          // if not or empty string then use corelogic data ("improvementsData")
          // https://goosehead.atlassian.net/browse/HQ-2672
          ...improvementsDataFromProperty,
          ...improvementsData,
        };
      }
      dispatch(
        addProperty({
          ...store.getState().property,
          ...propertyDataFromPolicy,
          ...improvementsData,
        })
      );

      dispatch(
        addContact({
          ...store.getState().contact,
          ...propertyDataFromPolicy,
        })
      );
    } catch (error) {
      dispatch(
        addProperty({
          ...propertyDefaults,
          ...store.getState().property,
          ...propertyDataFromPolicy,
          ...improvementsData,
        })
      );
      dispatch(
        addContact({
          ...propertyDefaults,
          ...store.getState().contact,
          ...propertyDataFromPolicy,
        })
      );
      console.error("Something went wrong", error);
    }
  };

  const fetchDownloadsAndPrefillData = async () => {
    dispatch(updateIsFetching(true));
    dispatch(
      addContact({
        ...store.getState().contact,
        isReshop: true,
        isHomePolicySelected: selectedHomePolicy ? true : false,
        isAutoPolicySelected: selectedAutoPolicy ? true : false,
        selectedAutoPolicy: selectedAutoPolicy?.Id,
        selectedHomePolicy: selectedHomePolicy?.Id,
      })
    );
    if (selectedHomePolicy) {
      const homeDownload = (await getDownloadsByPolicyId(selectedHomePolicy.Id))
        .data;
      try {
        const mortgageeBinds =
          (await getMortgageeDetailsByPolicyId(selectedHomePolicy?.Id ?? ""))
            .data ?? [];
        dispatch(
          addContact({
            mortgageeBinds,
          })
        );
      } catch (error) {
        console.error("Error fetching mortgagee details:", error);
      }

      await mapPropertyDataFromPolicy(selectedHomePolicy);

      let homeCoveragesForReshop = {
        effective_date__c: calculateEffectiveDate(
          selectedHomePolicy.Expiration_Date__c,
          selectedHomePolicy.Effective_Date__c
        ),
      };

      if (homeDownload?.Dwelling_Limit__c) {
        const {
          Wind_Hail_Deductible__c,
          All_Other_Deductible__c,
          Dwelling_Limit__c,
        } = homeDownload;
        homeCoveragesForReshop.wind_hail_deductible__c =
          mapCoveragesFromDownload(Wind_Hail_Deductible__c, Dwelling_Limit__c);
        homeCoveragesForReshop.all_other_deductible__c =
          mapCoveragesFromDownload(All_Other_Deductible__c, Dwelling_Limit__c);
        homeCoveragesForReshop.dwelling_limit__c = Dwelling_Limit__c;
      } else if (selectedHomePolicy?.Coverage__c) {
        const {
          Wind_Hail_Deductible__c,
          All_Other_Deductible__c,
          Coverage__c,
        } = selectedHomePolicy;
        homeCoveragesForReshop.wind_hail_deductible__c =
          mapCoveragesFromDownload(Wind_Hail_Deductible__c, Coverage__c);
        homeCoveragesForReshop.all_other_deductible__c =
          mapCoveragesFromDownload(All_Other_Deductible__c, Coverage__c);
        homeCoveragesForReshop.dwelling_limit__c = Coverage__c;
      }

      homeCoveragesForReshop = sanitizeCoverage(homeCoveragesForReshop);

      if (
        !propertyValueExistsInObject(
          stateSpecificWindHailOptions[selectedHomePolicy?.Property_State__c],
          "value",
          homeCoveragesForReshop?.wind_hail_deductible__c
        )
      ) {
        homeCoveragesForReshop.wind_hail_deductible__c = null;
      }

      if (
        !propertyValueExistsInObject(
          stateSpecificAopOptions[selectedHomePolicy?.Property_State__c],
          "value",
          homeCoveragesForReshop?.all_other_deductible__c
        )
      ) {
        homeCoveragesForReshop.all_other_deductible__c = null;
      }

      dispatch(
        addCoverages({
          ...store.getState().coverages,
          homeCoveragesForReshop,
        })
      );

      dispatch(
        addProperty({
          ...store.getState().property,
          effectiveDate: homeCoveragesForReshop.effective_date__c,
          dwellingCoverage: homeCoveragesForReshop.dwelling_limit__c,
          windHailDeductible: homeCoveragesForReshop.wind_hail_deductible__c,
          aopDeductible: homeCoveragesForReshop.all_other_deductible__c,
        })
      );
    }
    if (selectedAutoPolicy) {
      // store reshop auto quote request sf id for getting previous incidents in the next few pages
      if (selectedAutoPolicy.Quote_Request__c) {
        dispatch(
          addContact({
            previousAutoQuoteRequestSfId: selectedAutoPolicy.Quote_Request__c,
          })
        );
      }
      const autoDownload = (await getDownloadsByPolicyId(selectedAutoPolicy.Id))
        .data;
      const numberOfMonths = getMonthsFromCurrentDate(
        selectedAutoPolicy.Effective_Date__c
      );

      const isNumberOfMonthsLessThanAYear = numberOfMonths < 12;
      let autoCoveragesForReshop = {
        effective_date__c: calculateEffectiveDate(
          selectedAutoPolicy.Expiration_Date__c,
          selectedAutoPolicy.Effective_Date__c
        ),
        years_with_prior_carrier: getYearsFromCurrentDate(
          selectedAutoPolicy.Effective_Date__c
        ),
        // synchronize salesforce process does not accept a prior carrier selected
        // with 0 years with prior carrier and 0 months with prior carrier
        // so we set 1 as default in case number of months is 0 (difficult to have that case but just in case)
        months_with_prior_carrier:
          numberOfMonths === 0
            ? 1
            : isNumberOfMonthsLessThanAYear
            ? numberOfMonths
            : 0,
        prior_insurance_carrier: selectedAutoPolicy.Insurance_Carrier__c,
        prior_liability_limits: selectedAutoPolicy.Bodily_Injury__c,
      };

      if (!selectedHomePolicy) {
        await mapPropertyDataFromPolicy(selectedAutoPolicy);
      }

      if (autoDownload?.Bodily_Injury__c) {
        const { Bodily_Injury__c } = autoDownload;
        autoCoveragesForReshop.bodily_injury__c = Bodily_Injury__c;
      } else if (selectedAutoPolicy?.Bodily_Injury__c) {
        const { Bodily_Injury__c } = selectedAutoPolicy;
        autoCoveragesForReshop.bodily_injury__c = Bodily_Injury__c;
      }

      autoCoveragesForReshop = sanitizeCoverage(autoCoveragesForReshop);

      if (
        !stateSpecificBodilyInjuryOptions[
          selectedAutoPolicy?.Property_State__c
        ]?.includes(autoCoveragesForReshop?.bodily_injury__c)
      ) {
        autoCoveragesForReshop.bodily_injury__c = null;
      }

      dispatch(
        addCoverages({
          ...store.getState().coverages,
          autoCoveragesForReshop,
        })
      );
    }

    let lineOfBusiness = null;
    let pageStopped = null;

    if (selectedHomePolicy) {
      pageStopped = "PropertyInfo";
      if (selectedHomePolicy.Policy_Type__c) {
        const { Policy_Type__c, Policy_Form__c, Occupancy__c } =
          selectedHomePolicy;
        const selectedPolicy = POLICY_MAP[Policy_Type__c];
        const occupancy = Occupancy__c?.toLowerCase();
        dispatch(addContact({ selectedPolicy }));
        if (Policy_Type__c === "Renter's") {
          lineOfBusiness = "RenterAuto";
        } else if (Policy_Type__c === "Dwelling") {
          dispatch(
            addProperty({
              ...store.getState().property,
              occupancy: occupancy || Tenant,
            })
          );
          lineOfBusiness = "HomeAuto";
        } else {
          if (Policy_Form__c === policyFormTypes.DP3) {
            dispatch(
              addProperty({
                ...store.getState().property,
                occupancy: occupancy || Tenant,
              })
            );
          }
          lineOfBusiness = `${Policy_Type__c}Auto`;
        }
      }
    }

    if (selectedAutoPolicy && !selectedHomePolicy) {
      lineOfBusiness = "Auto";
      pageStopped = "AboutYou";
    }
    await handleUpdateSession({
      uuid: session.uuid,
      line_of_business: lineOfBusiness,
      page_stopped: getPagenumber(lineOfBusiness, pageStopped),
      agent_sfid: session.agent_sfid,
    });
    navigate(`/agent/${lineOfBusiness}/${pageStopped}`);
    dispatch(updateIsFetching(false));
  };

  const showPolicies = homePolicies.length > 0 || autoPolicies.length > 0;

  return (
    <>
      {showModal && (
        <ReshopModal
          show={showModal}
          setShow={setShowModal}
          content={reshopModalText}
        />
      )}
      {showPolicies ? (
        <>
          <PolicyTable>
            <thead>
              <tr>
                <th></th>
                <th>Policy</th>
                <th>Carrier</th>
                <th>Address</th>
                <th>Premium</th>
                <th>Exp. Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {homePolicies.map((policy, index) => {
                return (
                  <Policy
                    key={policy.Id}
                    policy={policy}
                    showBorder={
                      index === homePolicies.length - 1 && autoPolicies.length
                    }
                    index={index}
                    selectedPolicyIndex={selectedHomePolicyIndex}
                    setSelectedHomePolicyIndex={setSelectedHomePolicyIndex}
                    setSelectedAutoPolicyIndex={setSelectedAutoPolicyIndex}
                  />
                );
              })}
              {autoPolicies.map((policy, index) => {
                return (
                  <Policy
                    key={policy.Id}
                    policy={policy}
                    showBorder={false}
                    index={index}
                    selectedPolicyIndex={selectedAutoPolicyIndex}
                    setSelectedHomePolicyIndex={setSelectedHomePolicyIndex}
                    setSelectedAutoPolicyIndex={setSelectedAutoPolicyIndex}
                  />
                );
              })}
            </tbody>
          </PolicyTable>
          <div className="mt-5">
            <CustomButton
              title="Let’s Do This"
              handleClick={() => {
                if (
                  selectedHomePolicyIndex === null &&
                  selectedAutoPolicyIndex === null
                ) {
                  setShowModal(true);
                } else {
                  fetchDownloadsAndPrefillData();
                }
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div className="d-flex justify-content-center mt-5">
            <CustomText fontSize="medium" fontWeight="bold">
              This account does not have any policies to reshop.
            </CustomText>
          </div>
          <div className="d-flex justify-content-center mt-2">
            <CustomText
              fontSize="medium"
              fontWeight="bold"
            >{`Use the "New Quote" tab.`}</CustomText>
          </div>
        </>
      )}
    </>
  );
};

export default ReShop;
