import * as quoteService from "../../services/quote";

import { MonolineLob, Quote } from "../../types";
import { getIsBind, getIsBranch } from "../Quote/utils";
import {
  getPropertyData,
  updateCarrierPropertyData,
} from "../../services/property";
import { useDispatch, useSelector } from "../../redux/hooks";
import {
  v1CarrierIds,
  v3AutoCarrierIds,
  v3FloodCarrierIds,
  v3HomeCarrierIds,
} from "../../constants";

import { BIND_URL } from "../Quote/env";
import { handleUpdateSession } from "../../services/utils";
import { isValidDate } from "../../utils/strings";
import { prependHttp } from "../../utils";
import { url as salesforceDomain } from "../../utils/sales-force";
import { updateQuoteResponse } from "../../redux/actions/quote";
import { useIsCarrierQtiEnabled } from "../Quote/useIsCarrierQtiEnabled";
import { useIsV3_1QtiCarrierOverride } from "../Quote/useIsV3_1QtiCarrierOverride";
import { useState } from "react";

// Scray QTI stuff, do not touch unless necessary
// contact the QTI team first for changes
export const useQti = ({ quote, lob }: { quote: Quote; lob: MonolineLob }) => {
  const [bridged, setIsBridged] = useState(quote?.bridged__c ?? false);

  const session = useSelector((store) => store.session);
  const contact = useSelector((store) => store.contact);

  const { qti_quote_id, company_client_id__c: companyClientId } = quote;
  const [optimisticUpdate, setOptimisticUpdate] = useState(
    qti_quote_id === "optimisticUpdate"
  );
  const dispatch = useDispatch();

  const isCarrierQtiEnabled = useIsCarrierQtiEnabled({
    quoteToBind: "",
    companyClientId,
    lob,
  });
  const isV3_1QtiCarrierOverride = useIsV3_1QtiCarrierOverride({
    companyClientId,
    lob,
  });

  const isBranch = getIsBranch(quote);

  const isBind = getIsBind({
    companyClientId,
    qti_id: session.qti_id,
    quoteResponse: quote,
    isCarrierQtiEnabled,
  });

  const isLoginError = quote?.error_type__c === "Login Error";

  const qtiQuoteExists = !!qti_quote_id || optimisticUpdate;

  let shouldRenderNull = false;

  if (qti_quote_id === "optimisticUpdate" && !optimisticUpdate) {
    setOptimisticUpdate(true);
    shouldRenderNull = true;
  }

  const checkout = async () => {
    try {
      let redirectURL = "";
      if (isLoginError) {
        redirectURL = salesforceDomain + (quote.carrier_login__c ?? "");
      } else {
        setIsBridged(true);
        quoteService.updateQuoteResponse({
          heroku_id: quote.heroku_id,
          bridged__c: true,
        });

        if (isBind) {
          if (isBranch && contact.propertyId) {
            const qti_carrier_purchase_date = (
              await getPropertyData(contact.propertyId)
            ).data.ownership?.qti_carrier_purchase_date;

            // Entering QTI flow, we change the purchase date
            // to the date provided by the QTI carrier
            if (
              qti_carrier_purchase_date &&
              isValidDate(qti_carrier_purchase_date)
            ) {
              await updateCarrierPropertyData(contact.propertyId, {
                purchase_date__c: qti_carrier_purchase_date,
              });
            }
          }

          let homeCarrierCheckedOut;
          let autoCarrierCheckedOut;
          let floodCarrierCheckedOut;
          const v3CarrierIds =
            quote?.line_of_business__c?.toLowerCase() === "home"
              ? v3HomeCarrierIds
              : quote?.line_of_business__c?.toLowerCase() === "flood"
              ? v3FloodCarrierIds
              : v3AutoCarrierIds;
          const isV1Carrier = v1CarrierIds.includes(companyClientId);
          const isV3Carrier =
            v3CarrierIds.includes(companyClientId) || isV3_1QtiCarrierOverride;

          const versionString = isV1Carrier
            ? "/client/QTI/V1"
            : isV3Carrier
            ? "/QTI/V3"
            : "/QTI/V2";
          const homeCheckedOutVersionString = isBranch
            ? `${versionString}/Home/HomeReview`
            : `${versionString}`;
          const autoCheckedOutBundleString = isBranch
            ? `${versionString}/Auto/AutoReviewDrivers`
            : `${versionString}`;
          const floodCheckedOutBundleString = versionString;

          if (lob === "home") {
            homeCarrierCheckedOut = quote?.company_client_id__c;
            autoCarrierCheckedOut = null;
            floodCarrierCheckedOut = null;
            redirectURL = `${BIND_URL}${homeCheckedOutVersionString}?bindingFromAR=true&lobTobind=home&rs=${session.uuid}`;
          } else if (lob === "auto") {
            homeCarrierCheckedOut = null;
            autoCarrierCheckedOut = quote?.company_client_id__c;
            floodCarrierCheckedOut = null;
            redirectURL = `${BIND_URL}${autoCheckedOutBundleString}?bindingFromAR=true&lobTobind=auto&rs=${session.uuid}`;
          } else if (lob === "flood") {
            homeCarrierCheckedOut = null;
            autoCarrierCheckedOut = null;
            floodCarrierCheckedOut = quote?.company_client_id__c;
            redirectURL = `${BIND_URL}${floodCheckedOutBundleString}?bindingFromAR=true&lobTobind=auto&rs=${session.uuid}`;
          }

          //TODO: Will need to add logic for QTI bundling using v2/v3. May need to be in RecommendedQuotes component.
          const final_quotes_data = JSON.stringify([quote]);
          await handleUpdateSession({
            contact_data: JSON.stringify({
              ...contact,
              homeCarrierCheckedOut,
              autoCarrierCheckedOut,
              floodCarrierCheckedOut,
              carrierCheckedOut:
                homeCarrierCheckedOut ||
                autoCarrierCheckedOut ||
                floodCarrierCheckedOut,
              qti_id_v2: quote?.heroku_id,
            }),
            final_quotes_data,
          });

          const shouldOptimisticallyUpdateButtonTitle =
            !qtiQuoteExists && !isV1Carrier;
          if (shouldOptimisticallyUpdateButtonTitle) {
            setOptimisticUpdate(true);
            // Update the currentQtiSession state
            // so buttons will re-render w/ "Resume". On reload
            // the button will be driven by database state.
            dispatch(
              updateQuoteResponse({
                lob,
                quoteResponseId: quote.heroku_id,
                updates: { qti_quote_id: "optimisticUpdate" },
              })
            );
          }
        } else
          redirectURL =
            quote.company_url_long__c ??
            quote.company_url__c ??
            (quote?.carrier__c === "Branch"
              ? `https://www.ourbranch.com/staff/offer/${session?.qti_id}`
              : "");
      }
      redirectURL = prependHttp(redirectURL);
      const bridgeWindow = window.open(redirectURL, "_blank");
      bridgeWindow?.focus();
    } catch (error) {
      console.log("Error handling bridge", error);
    }
  };
  if (qti_quote_id === "optimisticUpdate" && !optimisticUpdate) {
    setOptimisticUpdate(true);
    shouldRenderNull = false;
  }
  return {
    checkout,
    shouldRenderNull,
    qtiQuoteExists,
    isBind,
    bridged,
  };
};
